<template>
  <div>
    <h1>{{ $t('strips.details.title') }}</h1>
    <div class="header">
      <div class="label">{{ $t('strips.details.description') }}</div>
      <div>{{ strip.description }}</div>

      <div class="label">{{ $t('strips.details.size') }}</div>
      <div>{{ strip.size }}</div>

      <div class="label">{{ $t('strips.details.irrigated') }}</div>
      <div>{{ strip.irrigated }}</div>
    </div>

    <h2>{{ $t('strips.details.actions') }}</h2>
    <div class="action-header">
      <div class="label">{{ $t('strips.details.nextAction') }}</div>
      <div>{{ strip.nextAction }}</div>

      <div class="label">{{ $t('strips.details.percentComplete') }}</div>
      <div>{{ strip.percentComplete }} %</div>
    </div>

    <StripActionList :strip="strip" @reload="loadStrip" />

    <h2>Assign action</h2>
    <AssignStripAction :strip="strip" @reload="loadStrip" />
  </div>
</template>

<style>
.header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.action-header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
</style>

<script>
import AssignStripAction from './AssignStripAction.vue'
import StripActionList from './StripActionList.vue'

export default {
  name: 'StripDetails',
  components: {
    AssignStripAction, StripActionList
  },

  data: () => ({
    strip: {}
  }),

  beforeMount () {
    this.loadStrip()
  },

  methods: {
    loadStrip () {
      fetch(`/api/strips/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.strip = data
        })
    }
  }
}
</script>
