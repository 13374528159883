<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>
        <span v-if="editMode">{{ $t('strips.editActionDialog.title') }}</span>
        <span v-else>{{ $t('strips.addActionDialog.title') }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-textarea :label="$t('strips.addActionDialog.description')" required rows="3" auto-grow
              v-model="stripAction.description"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select :items="monthsToSelect" :label="$t('strips.addActionDialog.earliestMonth')" type="number"
              v-model="stripAction.earliestMonth">
            </v-select>
          </v-col>
          <v-col>
            <v-text-field :label="$t('strips.addActionDialog.blockTime')" type="number"
              v-model="stripAction.blockTimeDays">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" @click="$emit('cancel')">{{ $t('terms.cancel') }}</v-btn>
        <v-btn data-cy="saveDialog" color="primary" @click="$emit('save', stripAction)">{{ $t('terms.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    actionToEdit: Object
  },

  data: () => ({
    monthsToSelect: [
      { text: 'January', value: 1 },
      { text: 'February', value: 2 },
      { text: 'March', value: 3 },
      { text: 'April', value: 4 },
      { text: 'May', value: 5 },
      { text: 'June', value: 6 },
      { text: 'July', value: 7 },
      { text: 'August', value: 8 },
      { text: 'September', value: 9 },
      { text: 'October', value: 10 },
      { text: 'November', value: 11 },
      { text: 'December', value: 12 }
    ],
    error: null,
    stripAction: {}
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  watch: {
    actionToEdit: {
      handler (value) {
        if (value) {
          this.stripAction = value
          this.editMode = true
        }
      },
      immediate: true
    }
  }

}
</script>
