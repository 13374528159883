<template>
  <v-data-iterator :items="actionPackages">
    <template v-slot:header>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn data-cy="addActionPackage" color="primary" dark @click.stop="showAddDialog = true">{{
          $t('strips.actionPackages.toolbar.add') }}</v-btn>
        <AddStripActionPackage :show="showAddDialog" @input="showAddDialog = $event" v-on:reload="loadActionPackages" />
      </v-toolbar>
    </template>
    <template v-slot:default="{ items }">
      <div class="action-package" v-for="actionPackage in items" :key="actionPackage.id">
        <div class="content">
          {{ actionPackage.name }}
        </div>
        <div>
          <v-btn text>{{ $t('terms.edit') }}</v-btn>
          <v-btn text @click="deletePackage(actionPackage.id)">{{ $t('terms.delete') }}</v-btn>
        </div>
      </div>
    </template>
  </v-data-iterator>
</template>

<style>
DIV.action-package {
  border: 1px solid silver;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  display: flex;
}

DIV.action-package .content {
  flex-grow: 1;
  font-size: larger;
  font-weight: bold;
}
</style>

<script>
import AddStripActionPackage from './AddStripActionPackage.vue'

export default {
  name: 'StripActionPackages',

  components: {
    AddStripActionPackage
  },

  data: () => ({
    actionPackages: [],
    showAddDialog: false
  }),

  beforeMount () {
    this.loadActionPackages()
  },

  methods: {
    loadActionPackages () {
      fetch('/api/strips/actions/packages', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.actionPackages = data
        })
    },

    deletePackage (packageId) {
      fetch(`/api/strips/actions/packages/${packageId}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            this.loadActionPackages()
          } else {
            console.log('Error deleting action package')
          }
        })
    }
  }

}
</script>
