<template>
  <v-data-table :items="strips" :headers="headers">
    <template v-slot:top>
      <v-toolbar>
        <v-btn data-cy="addStrip" color="primary" dark @click.stop="showAddDialog = true">{{
          $t('strips.overview.toolbar.add') }}</v-btn>
        <v-spacer></v-spacer>
        <AddStripDialog :show="showAddDialog" @input="showAddDialog = $event" v-on:reload="loadStrips" />
        <EditStripDialog :show="showEditDialog" :strip="stripToEdit" @input="showEditDialog = $event"
          v-on:reload="loadStrips" />
      </v-toolbar>
    </template>
    <template v-slot:[`item.percentComplete`]="{ item }">
      {{ formatPercent(item.percentComplete) }}
    </template>
    <template v-slot:[`item.details`]="{ item }">
      <v-icon large @click="showDetails(item.id)">mdi-folder-open-outline</v-icon>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon @click="editStrip(item)">mdi-pencil</v-icon>
      <v-icon @click="confirmDeleteStrip(item)">mdi-delete</v-icon>
      <DeleteConfirmationDialog :show="showDeleteDialog" :itemDescription="item.designator"
        @cancel="showDeleteDialog = $event" @confirm="deleteStrip(item)" />
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n'
import AddStripDialog from './AddStripDialog.vue'
import EditStripDialog from './EditStripDialog.vue'
import DeleteConfirmationDialog from '@/views/generic/DeleteConfirmationDialog.vue'

export default {
  name: 'StripsList',
  components: { AddStripDialog, EditStripDialog, DeleteConfirmationDialog },

  data: () => ({
    strips: [],
    stripToEdit: null,
    showAddDialog: false,
    showEditDialog: false,
    showDeleteDialog: false,
    headers: [
      { text: i18n.t('strips.list.details'), align: 'start', value: 'details' },
      { text: i18n.t('strips.list.designator'), align: 'start', value: 'designator' },
      { text: i18n.t('strips.list.description'), align: 'start', value: 'description' },
      { text: i18n.t('strips.list.size'), align: 'start', value: 'size' },
      { text: i18n.t('strips.list.irrigated'), align: 'start', value: 'irrigated' },
      { text: i18n.t('strips.list.complete'), align: 'start', value: 'percentComplete' },
      { text: i18n.t('strips.list.nextStep'), align: 'start', value: 'nextAction' },
      { text: i18n.t('strips.list.actions'), align: 'start', value: 'actions' }
    ]
  }),

  beforeMount () {
    this.loadStrips()
  },

  methods: {
    formatPercent (value) {
      return value + ' %'
    },

    loadStrips () {
      fetch('/api/strips', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.strips = data
        })
    },

    confirmDeleteStrip (item) {
      this.showDeleteDialog = true
    },

    deleteStrip (item) {
      fetch(`/api/strips/${item.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            this.showDeleteDialog = false
            this.loadStrips()
          }
        })
    },

    editStrip (item) {
      this.stripToEdit = item
      this.showEditDialog = true
    },

    showDetails (id) {
      this.$router.push({ name: 'StripDetails', params: { id } })
    }

  }
}
</script>
