<template>
  <StripActionDetailsDialog :show="showDialog" :actionToEdit="actionToEdit" @cancel="showDialog = false" @save="save" />
</template>

<script>
import StripActionDetailsDialog from './StripActionDetailsDialog.vue'

export default {
  props: {
    show: Boolean,
    actionToEdit: Object
  },

  components: { StripActionDetailsDialog },

  data: () => ({
    error: null
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },
  methods: {
    save (stripAction) {
      fetch(`/api/strips/actions/${stripAction.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(stripAction)
      }).then(response => {
        if (response.ok) {
          this.$emit('reload')
          this.showDialog = false
        } else {
          this.error = response.statusText
        }
      })
    }
  }
}
</script>
