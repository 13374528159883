<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.link" :to="item.link">{{ item.text }}</v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'StripsHome',
  components: {
  },
  data () {
    return {
      tab: null,
      items: [
        {
          text: i18n.t('strips.tab.overview'),
          link: '/strips/overview'
        },
        {
          text: i18n.t('strips.tab.stripsList'),
          link: '/strips/list'
        },
        {
          text: i18n.t('strips.tab.stripActions'),
          link: '/strips/actions'
        },
        {
          text: i18n.t('strips.tab.stripActionPackages'),
          link: '/strips/actions/packages'
        }
      ]
    }
  }
}
</script>
