<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>{{ $t('strips.addActionPackageDialog.title') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field :label="$t('strips.addActionPackageDialog.packageName')" required
              v-model="actionPackage.name"></v-text-field>
          </v-col>
        </v-row>
        <v-alert type="error" v-if="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" @click="showDialog = false">{{ $t('terms.cancel') }}</v-btn>
        <v-btn data-cy="saveDialog" color="primary" @click="save()">{{ $t('terms.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  },

  data: () => ({
    error: null,
    actionPackage: {}
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  methods: {
    save () {
      fetch('/api/strips/actions/packages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(this.actionPackage)
      }
      ).then(response => {
        if (response.ok) {
          this.$emit('reload')
          this.showDialog = false
        } else {
          this.error = response.statusText
        }
      })
    }
  }
}
</script>
