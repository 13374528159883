<template>
  <div>
    <StripDetailsDialog :show="showDialog" :zonesAvailable="zonesAvailable" @cancel="showDialog = false"
      @save="saveStrip" />
  </div>
</template>

<script>
import StripDetailsDialog from './StripDetailsDialog.vue'

export default {
  props: {
    show: Boolean
  },

  components: { StripDetailsDialog },

  data: () => ({
    error: null,
    zonesAvailable: []
  }),
  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },
  beforeMount () {
    this.loadZones()
  },
  methods: {
    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zonesAvailable = []
          data.zones.forEach((zone) => {
            this.zonesAvailable.push({
              text: zone.zoneName,
              value: zone.id
            })
          })
          this.zonesAvailable = this.zonesAvailable.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
        })
    },

    saveStrip (item) {
      console.log('saveStrip', item)

      fetch('/api/strips', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(item)
      }).then(response => {
        if (response.ok) {
          this.$emit('reload')
          this.showDialog = false
        } else {
          this.error = response.statusText
        }
      })
    }
  }
}
</script>
