<template>
  <v-data-table :headers="headers" :items="strips">
    <template v-slot:top>
      <v-toolbar>
        <v-btn data-cy="addAction" color="primary" dark @click.stop="showAddDialog = true">{{
          $t('strips.actions.toolbar.add') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn data-cy="listActionPackages" @click.stop="$router.push({ name: 'StripActionPackages' })">{{
          $t('strips.actions.toolbar.package') }}</v-btn>
        <AddStripActionDialog :show="showAddDialog" @input="showAddDialog = $event" v-on:reload="loadStripActions" />
        <EditStripActionDialog :show="showEditDialog" :actionToEdit="stripActionToEdit" @input="showEditDialog = $event"
          v-on:reload="loadStripActions" />
      </v-toolbar>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      {{ item.description }}
      <span v-if="item.belongsToPackages.length > 0">
        <v-icon small>mdi-package-variant-closed</v-icon>
        <span v-for="(actionPackage, index) in item.belongsToPackages" :key="actionPackage.id">
          {{ actionPackage.name }}
          <span v-if="index < item.belongsToPackages.length - 1">,</span>
        </span>
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon @click="editItem(item)">mdi-pencil</v-icon>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-package-variant-closed</v-icon>
        </template>

        <v-list>
          <v-list-item v-for="actionPackage in packagesAvailable" :key="actionPackage.id"
            @click="attachPackage(item.id, actionPackage.id)">
            <v-list-item-title>{{ actionPackage.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import i18n from '@/i18n'
import AddStripActionDialog from './AddStripActionDialog.vue'
import EditStripActionDialog from './EditStripActionDialog.vue'

export default {
  name: 'StripActions',

  components: {
    AddStripActionDialog,
    EditStripActionDialog
  },

  data: () => ({
    headers: [
      { text: i18n.t('strips.actions.tableHeaders.description'), align: 'start', value: 'description' },
      { text: i18n.t('strips.actions.tableHeaders.blockTime'), align: 'start', value: 'blockTimeDays' },
      { text: i18n.t('strips.actions.tableHeaders.actions'), align: 'start', value: 'actions' }
    ],
    strips: [],
    stripActionToEdit: null,
    packagesAvailable: [],
    showAddDialog: false,
    showEditDialog: false,
    showAddPackageDialog: false
  }),

  beforeMount () {
    this.loadStripActions()
    this.loadActionPackages()
  },

  methods: {
    loadStripActions () {
      fetch('/api/strips/actions', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.strips = data
        })
    },

    loadActionPackages () {
      fetch('/api/strips/actions/packages', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.packagesAvailable = data
        })
    },

    deleteItem (item) {
      fetch(`/api/strips/actions/${item.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            this.loadStripActions()
          }
        })
    },

    attachPackage (actionId, packageId) {
      fetch(`/api/strips/actions/${actionId}/package/${packageId}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            this.loadStripActions()
          }
        })
    },

    editItem (item) {
      this.stripActionToEdit = item
      this.showEditDialog = true
    }
  }
}
</script>
