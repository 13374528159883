<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>
        <span v-if="editMode">{{ $t('strips.editDialog.title') }}</span>
        <span v-else>{{ $t('strips.addDialog.title') }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-if="!editMode">
            <v-select :items="zonesAvailable" :label="$t('strips.addDialog.selectZone')"
              v-model="strip.zoneId"></v-select>
          </v-col>
          <v-col>
            <v-text-field :label="$t('strips.addDialog.size')" required v-model="strip.size"></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox :label="$t('strips.addDialog.irrigated')" v-model="strip.irrigated"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea :label="$t('strips.addDialog.description')" required rows="3" auto-grow
              v-model="strip.description"></v-textarea>
          </v-col>
        </v-row>
        <v-alert type="error" v-if="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" @click="$emit('cancel')">{{ $t('terms.cancel') }}</v-btn>
        <v-btn data-cy="saveDialog" color="primary" @click="$emit('save', strip)">{{ $t('terms.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    zonesAvailable: Array,
    stripToEdit: Object
  },

  data: () => ({
    editMode: false,
    error: null,
    strip: {}
  }),

  watch: {
    stripToEdit: {
      handler (value) {
        if (value) {
          this.strip = value
          this.editMode = true
          console.log('stripToEdit changed to ', value)
        }
      },
      immediate: true
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  }
}
</script>
