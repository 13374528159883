<template>
  <div>
    <h2>Upcoming Strip Actions</h2>
    <dl class="upcoming-strip-actions">
      <div v-for="(item, index) in upcomingStripActions" :key="index">
        <dt>{{ item.actionName }} <span v-if="item.earliestDate">({{ item.earliestDate }})</span> <span v-else>(any
            time)</span></dt>
        <dd>Should be performed at: <span v-for="(stripDesignator, stripIndex) in item.stripDesignators"
            :key="stripIndex">{{ stripDesignator
            }}<span v-if="stripIndex < item.stripDesignators.length - 1">, </span></span>
        </dd>
      </div>
    </dl>
  </div>
</template>

<style>
DL.upcoming-strip-actions DT {
  font-weight: bold;
  margin-top: 1em;
}
</style>

<script>
export default {
  name: 'StripOverview',

  data: () => ({
    upcomingStripActions: []
  }),

  beforeMount () {
    this.loadStrips()
  },

  methods: {
    loadStrips () {
      fetch('/api/strips/upcoming', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.upcomingStripActions = data
        })
    }
  }
}
</script>
