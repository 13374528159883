<template>
  <div>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <div class="strip-action-selector">
      <v-select v-model="selectedStripAction" :items="actionsAvailable" item-text="description" item-value="id"
        label="Actions available" />
      <v-btn @click="addStripAction">Add</v-btn>
    </div>
  </div>
</template>

<style>
DIV.strip-action-selector {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
</style>

<script>
export default {
  name: 'AssignStripAction',

  props: {
    strip: Object
  },

  data: () => ({
    error: null,
    actionsAvailable: [],
    selectedStripAction: null
  }),

  beforeMount () {
    this.loadStripActions()
  },

  methods: {
    reportError (message) {
      this.error = message
      setTimeout(() => {
        this.error = null
      }, 2500)
    },

    loadStripActions () {
      fetch('/api/strips/actions', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.actionsAvailable = data
        })
    },

    addStripAction () {
      console.log('addStripAction', this.strip, this.selectedStripAction)

      fetch(`/api/strips/${this.strip.id}/actions/${this.selectedStripAction}`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.$emit('reload')
          } else {
            this.reportError(response.statusText)
          }
        })
    }

  }
}
</script>
