<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Earlierst Start</th>
          <th>Description</th>
          <th>Done</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="action in stripActions" :key="action.id">
          <td>{{ formatDate(action.earliestStartDate) }}</td>
          <td>{{ action.stripAction.description }}</td>
          <td>{{ formatDate(action.doneDate) }}</td>
          <td>
            <v-icon @click="showDoneDialog = true">mdi-check</v-icon>
            <ActionDoneDialog :show="showDoneDialog" :stripAction="action.stripAction" @cancel="showDoneDialog = false"
              @done="markActionAsDone" />

            <v-icon @click="showDeleteDialog = true">mdi-delete</v-icon>
            <DeleteConfirmationDialog :show="showDeleteDialog" :itemDescription="action.stripAction.description"
              @cancel="showDeleteDialog = $event" @confirm="removeAction(action.stripAction.id)" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import formatting from '@/mixins/formatting'
import DeleteConfirmationDialog from '@/views/generic/DeleteConfirmationDialog.vue'
import ActionDoneDialog from './ActionDoneDialog.vue'

export default {
  name: 'StripActionList',

  props: {
    strip: Object
  },

  mixins: [formatting],
  components: { DeleteConfirmationDialog, ActionDoneDialog },

  data: () => ({
    error: null,
    stripActions: [],
    showDeleteDialog: false,
    showDoneDialog: false
  }),

  watch: {
    strip: function () {
      this.stripActions = this.strip.actions
    }
  },

  methods: {
    reportError (message) {
      this.error = message
      setTimeout(() => {
        this.error = null
      }, 2500)
    },

    markActionAsDone (action) {
      console.log('markActionAsDone', action)
      const actionId = action.id

      fetch(`/api/strips/${this.strip.id}/actions/${actionId}/done`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(action)
      })
        .then(response => {
          if (response.status === 200) {
            this.showDoneDialog = false
            this.$emit('reload')
          } else {
            this.reportError(response.statusText)
          }
        })
    },

    removeAction (actionId) {
      fetch(`/api/strips/${this.strip.id}/actions/${actionId}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.showDeleteDialog = false
            this.$emit('reload')
          } else {
            this.reportError(response.statusText)
          }
        })
    }

  }
}
</script>
