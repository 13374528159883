<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>Mark action as done </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            {{ actionBeingEdited.description }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false" :return-value.sync="pickedDate"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="pickedDate" :label="$t('zoneAccessIcon.date')" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="pickedDate" :max="maxDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateMenu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dateMenu.save(pickedDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" @click="$emit('cancel')">{{ $t('terms.cancel') }}</v-btn>
        <v-btn data-cy="saveDialog" color="primary" @click="save">{{ $t('terms.done')
        }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionDoneDialog',

  props: {
    show: Boolean,
    stripAction: Object
  },

  data: () => ({
    dateMenu: false,
    maxDate: new Date().toISOString().substring(0, 10),
    pickedDate: new Date().toISOString().substring(0, 10),
    actionBeingEdited: {}
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  watch: {
    stripAction: {
      handler (value) {
        if (value) {
          this.actionBeingEdited = value
        }
      },
      immediate: true
    }
  },

  methods: {
    save () {
      this.actionBeingEdited.doneDate = this.pickedDate
      this.$emit('done', this.actionBeingEdited)
    }
  }

}
</script>
